import React, { useEffect, useRef, useState } from 'react'
import { DocumentDetails,/*  DocumentDropdown, */ DocumentImage, FileDocumentThumbnail, UploadDiv, /* UploadDocumentThumbnail, */ UploadDocumentThumbnailNew } from '../styles'
import { ImageUrl } from '../../../utils/constants'
// import LazyImage from '../../common/LazyImage/LazyImage';
import { CustomDropdownMenuItem, CustomDropdownMenue } from '../../AdminTeamCreation/styles';
import DeletePopupModal from './DocumentComponents/DeletePopupModal';
import { PropTypes } from 'prop-types';
// import _ from "lodash";
import { toast } from 'react-toastify';
import { DeleteRewardsDocumentForCompany, GetCompanyRewardsDocuments, UpdateRewardsDocumentNameForCompany, UploadRewardDocuments } from '../../../redux/constants/apiConstants';
import { fetchApi } from '../../../utils/methods';
import { getCompanyRewardsDocuments } from '../../../redux/actions';
import { connect } from 'react-redux';
import { RewardsDocumentUploadButtonIcon, RewardsDocumentUploadDocumentButtonIconWhiteArrow, RewardsPointsManagementDocumentOperationDropdownButtonIcon, RewardsPointsManagementDownloadDocumentButtonIcon } from '../../../utils/icons';

function DocumentDashboard({documentsData, refreshDocumentDashboard, companyInfo}) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [extensionOfFile, setExtensionOfFile] = useState('');
  const [fileNameForRename, setFileNameForRename] = useState('');
  const [activeDocumentIndex, setActiveDocumentIndex] = useState(null);
  const [activeRename, setActiveRename] = useState(false);
  const [activeDeleteModal, setActiveDeleteModal] = useState(null);
  const dropDocumentFile = useRef(null);
  const fileNameRef = useRef(null);
  const selectedRewardCompanyId = companyInfo && companyInfo.id;

  const handleDragOverDocumentFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
  }

  const handleDropDocumentFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const {files} = e.dataTransfer;

    if ( files && files.length && (files.length === 1) ) {
      uploadDocument(files[0])
    }
  }

  const validateDocument = (file) => {
    let validTypes = ["pdf", "png", "jpg", "doc", "docx","jpeg","csv"];
    let extenstion = file.split('.').pop();
    let isValid = validTypes.includes(extenstion);
    return isValid;
  }

  const uploadDocument = async (filesArray) => {
    window.console.log("filesArray",filesArray);

    // let isValidFileFormat = true;
    
    // if(filesArray.name){
    //   if(!validateDocument(filesArray.name)){
    //     isValidFileFormat = false;
    //     toast.error("Invalid file! Please select valid file format");
    //     return false;
    //   }
    // }
    

    // if(isValidFileFormat){
    //   const fileReader = new FileReader();
    //   fileReader.readAsDataURL(filesArray);
  
    //   let formData = {};
    //   formData.company_id = companyInfo.id;
  
    //   fileReader.onloadend = async function(){
    //     formData.document_data = [{
    //       title: filesArray.name,
    //       document_image: '',
    //       document: await fileReader.result
    //     }]
       
    //     await fetchApi(`${UploadRewardDocuments}`, "POST", formData).then((response) => {refreshDocumentDashboard(companyInfo.id);toast.success(response.data[0]);});
    //   }
    // }

    if(filesArray?.name){
      if(validateDocument(filesArray?.name)){
        const fileReader = new FileReader();
        fileReader.readAsDataURL(filesArray);
    
        let formData = {};
        formData.company_id = companyInfo.id;
    
        fileReader.onloadend = async function(){
          formData.document_data = [{
            title: filesArray?.name,
            document_image: '',
            document: await fileReader.result
          }]
         
          await fetchApi(`${UploadRewardDocuments}`, "POST", formData).then((response) => {
            refreshDocumentDashboard(companyInfo.id);
            toast.success(response.data[0]);
            document.getElementById('documentFileInput').value = '';
            
          });
          
        }

      }else{
        toast.error("Invalid file! Please select valid file format");
      }
    }
  }

  const renameDocument = async (doc_id) => {
    await fetchApi(`${UpdateRewardsDocumentNameForCompany}`, "PUT", {doc_id: doc_id, doc_rename: fileNameForRename+"."+extensionOfFile}).then((response) => {toast.success(response.data[0]); refreshDocumentDashboard(companyInfo.id)}).catch( (error) => {toast.error(error)} );

  }

  const deleteRewardsDocument = async (doc_id) => {
    await fetchApi(`${DeleteRewardsDocumentForCompany}/${doc_id}`, "DELETE").then((response) => {toast.success(response.data[0]); setShowDeleteModal(false); refreshDocumentDashboard(companyInfo.id)}).catch( (error) => {toast.error(error)} );
  }

  useEffect( () => {

    if(dropDocumentFile.current){
      dropDocumentFile.current.addEventListener('dragover', handleDragOverDocumentFile);
      dropDocumentFile.current.addEventListener('drop', handleDropDocumentFile);

      return () => {
        if(dropDocumentFile.current){
          dropDocumentFile.current.removeEventListener('dragover', handleDragOverDocumentFile);
          dropDocumentFile.current.removeEventListener('drop', handleDropDocumentFile);
        }
      };
    }
  }, [selectedRewardCompanyId] );

  useEffect( () => {
    const handleScroll = () => {
      setActiveDocumentIndex(null);
    }
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  const downloadFile = (ImageBase64, document_title) => {
    const downloadLink = document.createElement("a");
    downloadLink.download = document_title;
    downloadLink.target = '_blank';
    downloadLink.href = ImageBase64;
    downloadLink.click();
  }

  // const downloadRewardsDocument = async (document_id, document_title) => {
  //   await getCompanyRewardsDocuments(document_id);
  //   downloadFile(companyRewardsDocuments.reward_document, document_title);
  // }


  // To Fetch All Rewards Documents for a selected company
  const downloadRewardsDocument = async (documentId, title) => {
    try{
      const res = await fetchApi(`${GetCompanyRewardsDocuments}/${documentId}`, "GET");
      if(res?.data?.message){
        toast.error(res?.data?.message);
      }else{
        if(res?.data?.reward_document){
          downloadFile(res?.data?.reward_document, title);
        }else{
          toast.error("No document found to download");
        }
        
      }

    }catch(err){
      toast.error(err);
    }

    
  }

  const setFileName = (docTitle) => {
    let temp = docTitle.slice(0, docTitle.lastIndexOf('.'));
    let tempStrArray = docTitle.split('.');

    setExtensionOfFile(tempStrArray[tempStrArray.length-1]);
    setFileNameForRename(temp);
  }

  const HandleEnterKeyFileRename = (e, documentID) => {
    if(e.key === 'Enter'){
      renameDocument(documentID); 
      setActiveRename(null);
    }
  }

  return (
    <React.Fragment>
      <UploadDiv>
        {
          documentsData && documentsData?.map( (document) => 
            <FileDocumentThumbnail key={document.id}>
              <DocumentImage isHovered={document.id==activeDocumentIndex} onMouseEnter={()=>{setActiveDocumentIndex(document.id)}} onMouseLeave={()=>{setActiveDocumentIndex(null)}} >
                <img src={ImageUrl+'/'+document.document_image} />
                <div className="buttonGroup">
                  <div className="downloadFileButton" onClick={() => downloadRewardsDocument(document.id, document.title) }>
                    {RewardsPointsManagementDownloadDocumentButtonIcon()}
                    Download File
                  </div>
                </div>
              </DocumentImage>
              <DocumentDetails>
                <div className="title" title={document.title}>
                  { activeRename != document.id ? document.title : <input type="text" ref={fileNameRef} value={fileNameForRename} className='documentTitleInput' 
                    onChange={(e)=>{
                      e.target.value.split('').includes('.') 
                        ? toast.error("Invalid Character - '.' not allowed") 
                        : setFileNameForRename(e.target.value)
                    }} onBlur={()=>{ renameDocument(document.id); setActiveRename(null);}} onKeyDown={(e)=> HandleEnterKeyFileRename(e, document.id)} /> }
                </div>
                <div className="documentOptionsDiv">

                  {/* <LazyImage alt="image" src={ImageUrl + "/images/rewards-management/three_dots_image.png"} /> */}
                  {RewardsPointsManagementDocumentOperationDropdownButtonIcon()}

                  <CustomDropdownMenue id="dropdown-recurring">
                    <CustomDropdownMenuItem
                      eventKey={1}
                      onClick={() => {
                        setActiveRename(document.id);
                        setFileName(document.title);
                        window.setTimeout(()=>{fileNameRef.current && fileNameRef.current.focus()}, 0)
                      }}
                    >
                      Rename
                    </CustomDropdownMenuItem>
                    <hr />
                    <CustomDropdownMenuItem
                      eventKey={2}
                      onClick={() => {
                        setShowDeleteModal(true);
                        setActiveDeleteModal(document.id);
                      }}
                    >
                      Delete
                    </CustomDropdownMenuItem>{" "}
                  
                  </CustomDropdownMenue>

                </div>
                {
                  (showDeleteModal && activeDeleteModal == document.id) && <DeletePopupModal showModal={true} setShowDeleteModal={setShowDeleteModal} deleteRewardsDocument={deleteRewardsDocument} doc_id={document.id} />
                }
              </DocumentDetails>
            </FileDocumentThumbnail>
          )
        }

        {/* <UploadDocumentThumbnail>
          <div className="innerThumbnail" ref={dropDocumentFile}>
            <label htmlFor="document-file">
              <img src={ImageUrl+""+"/images/rewards-management/upload_document.png"} />
            </label>
            <input type="file" name="" id="document-file" onChange={(e)=>{uploadDocument(e.target.files[0]);}} />
            <div className="uploadDocumentText">
              drag or select .pdf, .jpg, .doc <br />
              files to upload
            </div>
          </div>
        </UploadDocumentThumbnail> */}

        <UploadDocumentThumbnailNew>
          <div className="innerThumbnail" /* ref={dropDocumentFile} */>
            <div className="uploadDocumentIcon">
              {RewardsDocumentUploadDocumentButtonIconWhiteArrow()}
            </div>
            <div className="uploadDocumentTitle">
              Upload File
              <br/>
              <span>(pdf, png, jpg, doc, docx, jpeg, csv)</span>
            </div>

            <div className="fileInputWrapper uploadDocumentButton">
              <input type="file" name="" id="documentFileInput" className='file-input' onChange={(e)=>{window.console.log("e",e);uploadDocument(e.target.files[0]);}} />
              {RewardsDocumentUploadButtonIcon()}
              <label htmlFor="documentFileInput"> Add Document</label>
            </div>            
          </div>
        </UploadDocumentThumbnailNew>

      </UploadDiv>
    </React.Fragment>
  )
}

DocumentDashboard.propTypes = {
  selectedRewardCompany: PropTypes.string,
  documentsData: PropTypes.array,
  refreshDocumentDashboard: PropTypes.func,
  getCompanyRewardsDocuments: PropTypes.func,
  companyRewardsDocuments: PropTypes.array,
  companyInfo:PropTypes.object
}

const mapStateToProps = (state) => ({
  companyRewardsDocuments: state.wellnessDashboard.companyRewardsDocuments
});

const mapDispatchToProps = (dispatch) => ({
  getCompanyRewardsDocuments: (id) => dispatch(getCompanyRewardsDocuments(id)),
});

export default connect(mapStateToProps, mapDispatchToProps) (DocumentDashboard);
